<template>
  <div>
    <div class="block banner bg pscdn">
      <div class="container">
        <div class="box">
          <h1>{{this.$t('productScdn')}}</h1>
          <p>{{$t('product48')}}</p>
          <p class="des mobile">{{$t('product49')}}</p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :span="17">
            <h2>{{$t('product50')}}</h2>
            <p>{{$t('product51')}}</p>
          </el-col>
          <el-col :span="7">
            <OtherList hideRoute="productscdn" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product52')}}</h2>
        <ul class="list1">
          <li class="icon9">
            <h5>{{$t('product53')}}</h5>
            <p>{{$t('product54')}}</p> 
          </li>
          <li class="icon35">
            <h5>{{$t('product55')}}</h5>
            <p>{{$t('product56')}}</p> 
          </li>
          <li class="icon33">
            <h5>{{$t('product57')}}</h5>
            <p>{{$t('product58')}}</p>  
          </li>
          <li class="icon44">
            <h5>{{$t('product59')}}</h5>
            <p>{{$t('product60')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <h2>
          {{this.$t('product7')}}
        </h2>
        <ul class="product-list child">
          <li>
            <div class="img"><img src="@/assets/images/image4.svg" /></div>
            <div class="cont">
              <h4>{{$t('product61')}}</h4>
              <p>{{$t('product62')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image5.svg" /></div>
            <div class="cont">
              <h4>{{$t('product63')}}</h4>
              <p>{{$t('product64')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image6.svg" /></div>
            <div class="cont">
              <h4>{{$t('product65')}}</h4>
              <p>{{$t('product66')}}</p>
            </div>
          </li>
          <li>
            <div class="img"><img src="@/assets/images/image7.svg" /></div>
            <div class="cont">
              <h4>{{$t('product67')}}</h4>
              <p>{{$t('product68')}}</p>
            </div>
          </li>
        </ul>
        <ApplyBox />
      </div>
    </div>
  </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
export default {
  name: "ProductScdn",
  components: {
    OtherList,
    ApplyBox
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('productScdn')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }
</style>
